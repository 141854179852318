import serverAPI from "../utils/serverAPI";

export const sendFCMToken = async (token) => {
  try {
    const response = await serverAPI.put("/accounts/update-fcm-token/", {
        fcm_token: [token],
    });
    console.log("Token sent successfully:", response.data);
  } catch (error) {
    console.error("Error sending FCM token:", error.response?.data || error.message);
  }
};
