import React, { useState } from "react";
import { Tabs as MuiTabs, Tab, Box } from "@mui/material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2, backgroundColor: "#FAFAFA", borderRadius: "8px" }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export function Tabs({ tabs, query }) {
  const [value, setValue] = useState(0);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
        <MuiTabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          aria-label="basic tabs example"
          sx={{
            "& .MuiTab-root": {
              textTransform: "capitalize",
              fontSize: "1rem",
              fontWeight: 500,
              color: "text.secondary",
              "&:hover": {
                color: "text.primary",
              },
              "&.Mui-selected": {
                color: "primary.main",
                fontWeight: "bold",
                borderBottom: "2px solid",
                borderColor: "primary.main",
              },
            },
          }}
        >
          {tabs.map((item) => (
            <Tab
              key={item.id}
              label={item.buttonLabel}
              {...a11yProps(item.id)}
            />
          ))}
        </MuiTabs>
      </Box>
      <Box sx={{ flex: 1, overflowY: "auto", height: "calc(100% - 48px)" }}>
        {tabs.map((item) => (
          <TabPanel value={value} index={item.id} key={item.id}>
            {typeof item.component === "string"
              ? item.component
              : React.cloneElement(
                  item.component,
                  item?.componentProps
                    ? { ...item?.componentProps, query }
                    : { query }
                )}
          </TabPanel>
        ))}
      </Box>
    </Box>
  );
}
