// Import the required Firebase modules
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';

// Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyB-ZZ7TEQb5ny6HXtoAhlHwEcMhqBl600A',
  authDomain: 'itsm-1effb.firebaseapp.com',
  projectId: 'itsm-1effb',
  storageBucket: 'itsm-1effb.firebasestorage.app',
  messagingSenderId: '983617588602',
  appId: '1:983617588602:web:5297fd61969c3c21cd1ac3',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Messaging
const messaging = getMessaging(app);

// Request FCM Token
export const requestFCMToken = async () => {
  try {
    const token = await getToken(messaging, { vapidKey: 'BO_1HR63UVcuBAFFrnnLdWqw3Lt-liifDTMmD1KMjSFC-5_snKFGNXFK5fkUHzMGxlfUmy2yIBwKAIWm-oAHOyk' });
    console.log("FCM token retrieved:", token);
    return token;
  } catch (error) {
    console.error("An error occurred while retrieving the token:", error);
  }
};
