import React from "react";
import { Tabs } from "../../components/layouts/commonLayouts/tabs";
import { Box, Typography, Paper } from "@mui/material";

const getRelativeTime = (dateString) => {
  const now = new Date();
  const updatedTime = new Date(dateString);
  const diffInSeconds = Math.floor((now - updatedTime) / 1000);

  if (diffInSeconds < 60) {
    return `${diffInSeconds} seconds ago`;
  } else if (diffInSeconds < 3600) {
    const minutes = Math.floor(diffInSeconds / 60);
    return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
  } else if (diffInSeconds < 86400) {
    const hours = Math.floor(diffInSeconds / 3600);
    return `${hours} hour${hours > 1 ? "s" : ""} ago`;
  } else {
    const days = Math.floor(diffInSeconds / 86400);
    return `${days} day${days > 1 ? "s" : ""} ago`;
  }
};

const UpdateHistory = ({ updateLogs }) => (
  <Box className="p-4 bg-white rounded-lg">
    {updateLogs.map((logItem) =>
      logItem.logs.map((log) => (
        <div key={log.id} className="mb-4">
          <Typography variant="body2" color="text.secondary">
            <strong>{logItem.updated_by}</strong> updated the{" "}
            <strong>{log.changed_field_name}</strong>{" "}
            {getRelativeTime(logItem.created_on)}.
          </Typography>
          <Typography variant="body2" className="mt-1">
            {log.old_value === "-" ? "None" : log.old_value || "None"}{" "}
            <span className="mx-2">→</span> {log.new_value || "None"}
          </Typography>
          <Typography variant="body2" className="mt-1">
          <strong>Comment : </strong> {logItem.text || "No comment available"}
        </Typography>
        </div>
      ))
    )}
  </Box>
);

const Comments = ({ updateLogs }) => (
  <Box className="p-4 bg-white rounded-lg">
    {updateLogs.map((logItem) => (
      <Box className="mb-4">
        <Typography variant="body2" color="text.secondary">
          <strong>{logItem.updated_by}</strong> commented{" "}
          {getRelativeTime(logItem.created_on)}:
        </Typography>
        <Typography variant="body2" className="mt-1">
          {logItem.text || "No comment available"}
        </Typography>
      </Box>
    ))}
  </Box>
);

const UpdateLogCard = ({ data }) => {
  if (!data?.update_logs || data.update_logs.length === 0) {
    return null;
  }

  const tabs = [
    {
      id: 0,
      buttonLabel: "History",
      component: <UpdateHistory updateLogs={data.update_logs} />,
    },
    // {
    //   id: 1,
    //   buttonLabel: "Comments",
    //   component: <Comments updateLogs={data.update_logs} />,
    // },
  ];

  return (
    <Box className="w-full ">
      <Tabs tabs={tabs} />
    </Box>
  );
};

export default UpdateLogCard;
